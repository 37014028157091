// https://www.npmjs.com/package/slick-carousel
import $ from 'jquery';

$(($) => {
	var prevArrow = $('.slideshowControls').find('.slideshowControls__arrow.-prev');
	var nextArrow = $('.slideshowControls').find('.slideshowControls__arrow.-next');

	$('.js-TrailSlideshow').on('init afterChange', function(e, slick){
		let currentSlick = $('.slick-current.slick-active', slick.$slideTrack);
		let currentName = currentSlick.data('title');
		let prevRating = currentSlick.prev().data('rating');
		let nextRating = currentSlick.next().data('rating');
		let currentRating = currentSlick.data('rating');
		slick.$prevArrow.attr('data-rating', prevRating);
		slick.$nextArrow.attr('data-rating', nextRating);
		$('.slideshowControls__current').text(currentName).attr('data-rating', currentRating);
	});

	var $trailSlideshow = $('.js-TrailSlideshow').slick({
		arrows: true,
		autoplay: false,
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: prevArrow,
		nextArrow: nextArrow,
	});

	$('.trailSlideshowQuickJump__link').on('click', function() {
		var rating = $(this).data('rating');
		var slideIndex = $('.js-TrailSlideshow .slide[data-rating="'+rating+'"').first().index();
		$trailSlideshow[0].slick.slickGoTo(parseInt(slideIndex - 1));
		$('.trailSlideshowQuickJump__link').removeClass('-selected');
		$(this).addClass('-selected');
	});


	$('.js-GenericSlideshow').slick({
		arrows: false,
		dots: true,
		fade: true,
	});

});